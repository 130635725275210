import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTransition from "gatsby-plugin-page-transitions"
import { graphql, useStaticQuery, Link } from "gatsby"
import Image from "gatsby-image"
import { useStateValue } from "../contextProvider/StateProvider"
import styled from "@emotion/styled"


const StyledLink = styled(Link)`
  background: ${props => props.color};
  color: white;
  padding: 15px 20px;
  border-radius: 25px;
  letter-spacing: 1px;
  text-transform: uppercase;
  &:hover {
    opacity: 0.7;
  }
`

const Index = styled.div`
  width: 70vw;
  margin: auto;
  text-align: center;

  @media (min-width: 1060px) {
    max-width: 85vw;
    height: 100vh;
    text-align: left;
    display: flex;
    align-items: center;
    margin: auto;
  }
`

const ImageContainer = styled.div`
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid rgb(65, 62, 62);
  display: none;

  @media (min-width: 450px) {
    display: block;
    margin: 100px auto 30px;
  }

  @media (min-width: 1060px) {
    border-radius: 10px;
    display: inline-block;
    width: 550px;
    height: 50vh;
    margin: 0;
    max-height: 800px;
  }

  @media (min-width: 1400px) {
    height: 50vh;
  }
`

const H4 = styled.h4`
  margin-top: 35px;
`

const TextContainer = styled.div`
  @media (min-width: 1060px) {
    max-width: 550px;
    margin-left: 50px;
  }
`

const Greeting = styled.h1`
  margin-top: 150px;
  font-size: 20px;
  text-transform: "uppercase";
  @media(min-width: 450px) {
    margin-top: 15px;
  }
`
const NameContainer = styled.h1`
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: 800;
`

const Name = styled.span`
  color: ${props => props.color};
`

const IndexPage = props => {
  const [{ color }] = useStateValue()
  const data = useStaticQuery(graphql`
    query MyQuery {
      imageSharp(fluid: { originalName: { eq: "ayush.jpg" } }) {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)
  const fluid = data.imageSharp.fluid
  return (
    <Layout>
      <SEO title="Home" />
      <PageTransition
        transitionStyles={{
          entering: { opacity: "0.2" },
          entered: { opacity: "1" },
          exiting: { opacity: "0" },
        }}
        transitionTime={300}
      >
        <Index>
          <ImageContainer>
            <Image fluid={fluid} alt="Ayush Mainali"/>
          </ImageContainer>
          <TextContainer>
            <Greeting>
              Hi there!
            </Greeting>
            <NameContainer>
              I'm{" "}
              <Name color = {color}>
                Ayush Mainali
              </Name>
            </NameContainer>
            <p>
              I'm a web designer & front‑end developer focused on crafting clean
              & user‑friendly experiences, I am passionate about building
              excellent software that improves the lives of those around me.
            </p>
            <H4>
              <StyledLink to="/about" color = {color}>
                More About Me
              </StyledLink>
            </H4>
          </TextContainer>
        </Index>
      </PageTransition>
    </Layout>
  )
}

export default IndexPage
